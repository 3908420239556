
import React from 'react'
// Get our ReactRouter, which everything is bound here in app
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'

import LoginView from '../core/views/loginView'

import { GET_ME_QUERY } from '../lib/queries/auth'

import AuthLoaderPage from '../core/pages/authLoaderPage'

import ErrorPage from '../core/pages/errorPage'

import AppRoutes from './routes'

/* attempt an auth connection first, and write to cache */
// that way on any other route it can check local cache to see if we need login
// added withRouter so I could get access to the routers (location)

/*
 * 1. Display Loader if page loading
 * 2. If AuthLogin Error, check route, if not login redirect and render login page
 * 3. If we get a `data` response, render primary <Switch /> with all application routes
 *    - Provides the ability to customize the <Switch /> for a role, once we have `/me`
 * */
const AuthMiddleware = withRouter(({ props, history, location }) => {
  // console.log('came in here?')
  // Call the ME/QUERY and this request will either fail auth (send-to-login)
  // or a valid request with their user-info/staus

  const { loading, error, data } = useQuery(GET_ME_QUERY, {
    // prevent query data from being cached, in case user status changes during a session
    fetchPolicy: 'no-cache',
    // frequency to fetch new data in ms
    // set to check every 5 minutes
    pollInterval: 1000 * 60 * 5
  })

  // Helpful debuggers
  // console.log('<Auth--QUERY fired (ROUTER) /> ', props, location)
  // console.log('<Auth--QUERY fired (status) /> ', loading, error)
  // console.log('<Auth--QUERY fired (data) /> ', data)

  // Viewing Error -- which has several keys, console.log(error) renders the message
  if (loading) {
    console.log('<Auth Loading />')
    // TODO Put a solid bg-loader here, make a simple page that fast to render
    // Allow it to take a prop, as there is another loading one we get further in
    return (<AuthLoaderPage />)
  }

  if (error) {
    console.log('error[network]->', error.networkError.name)
    console.log('error[network]->', error.networkError.statusCode)
  }

  // Apollo USE_QUERY will return `error.networkError` if it is able to establish a connection to the API server but receives an error
  // in most cases this will be a 401-UnAuthorized
  // it will be undefined if the server is unresponsive
  if (error && error.networkError.statusCode === undefined && 'message' in error && data === undefined) {
    return (<ErrorPage error={error} />)
  }

  // Should be able to catch someone isn't logged in and display that as the issue on this
  // -NOT SURE IF WE NEED TO ERROR HANDLE HERE, let this flow
  // if (error){
  // return <p>Auth Error...</p>;
  // }

  // TODO - should track 500's to a service like rollbar or something
  // 401 - Is UnAuthorized / Missing Token so catch these
  // error is an object-literal contains 'graphQLErrors' and 'networkError'
  if ((error && error.networkError.statusCode === 401) ||
    (data && data.me && data.me.isActive === false)) {
    // console.log('Not Authenticated')
    // direct to login page, not sure how we get it bootstrapped yet
    // could do a manual history set, and render the login page
    if (location.pathname !== '/login') {
      // manually push us to login (not using <Redirect to="" />) because we
      // need to still bind login route post redirect, and can't do both
      history.push('/login')
    }
    // Switch is a convience wrapper to mount and render
    return (
      <Switch>
        <Route path='/login' component={LoginView} />
      </Switch>
    )
  }

  // TODO
  // https://github.com/JeffML/create-react-app/blob/master/src/ExchangeRates.js

  // console.log('<Auth /> Data,', data)
  // console.log('<Auth /> Client,', client)
  if (data) {
    // Access our Main Routes File
    return AppRoutes()
  }
})

export default AuthMiddleware
