import React from 'react'
import styled from 'styled-components'

// Molecules
import Workspace from '../molecules/WorkspaceSection/Workspace'
// import ConsoleNav from '../molecules/ConsoleSection/ConsoleNav'

const Aligner = styled.div`
  display: flex;
  flex-direction: column;
`

const ConsolePage = () => {
  return (
    <Aligner>
      <Workspace />
      {/* <ConsoleNav /> */}
      {/* <ConsoleDisplay /> */}
      {/* Footer */}
    </Aligner>
  )
}

export default ConsolePage
