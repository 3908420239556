/*
 *  Summary:
 *  Views are meant as a portal component that connects a top-level route to a `page/` which can then be configured
 *  Every page requires the `React` import and a `page/` reference
 */
// React Include
import React from 'react'

// Got some styling to do initially
import styled from 'styled-components/macro'

import AppPage from '../../core/pages/appPage'

// From ApolloClient load the useQuery react-hook helper
import { useQuery } from '@apollo/client'

import DiscoveryCollectionList from '../organisms/DiscoveryCollectionList'
import DiscoveryCollectionDetail from '../organisms/DiscoveryCollectionDetail'

/* AppPage  > AppMainSection
   sets the flexbox into play, any
   inner box needs to now have flex enabled
   or we cheat here and just set a width and no longer use flex...
   if we move back to flex use `display:flex, flex: 1 1 auto;`

   ---

   okay the AppPage has a mainSection that sets up a column flexbox, so now we can position this as our center wrapper

   */
const MainSectionFrame = styled.div`
  
  display:flex;
  // Let this region expand and grow to fill the parent AppMainSection -- import for Detail
  flex-grow: 1;
  // TODO - consider a lock for DiscoveryDetail
  //max-width:1000px;
  // debug border
  //border: 1px dotted #333
  
`

function DiscoveryView (props) {
  // console.log('Discovery [VIEW]:', this);

  // unpackage the react-router splat params from `match` key
  const routerParams = props.match
  // get our section (collection, topics, stacks)
  // ex. of routerParams.params.detailId `Understanding-GitHub-Processing-Part-1-0-d964aab3-9b5c-5a9d-9854-8477f414821f`
  // parsing routerParams.params.detailId for collection id/uuid id
  // uuid is 5 sections and always at the end
  let detailTitleUrlSlug = (routerParams.params.detailId) ? routerParams.params.detailId : ''
  detailTitleUrlSlug = detailTitleUrlSlug.split('-')
  // console.log('[Discovery Router] --  detailTitleUrlSlug: ',detailTitleUrlSlug)
  const detailId = (detailTitleUrlSlug.length > 5) ? detailTitleUrlSlug.slice(detailTitleUrlSlug.length - 5, detailTitleUrlSlug.length).join('-') : detailTitleUrlSlug.join('-')
  // console.log('[Discovery Router] --  detailId: ',detailId)
  let panel = ''

  if (detailId !== '') {
    panel = (<DiscoveryCollectionDetail uuid={detailId} />)
  } else {
    panel = (<DiscoveryCollectionList />)
  }

  // To handle mounting
  return (
    <AppPage>
      <MainSectionFrame>
        {panel}
      </MainSectionFrame>
    </AppPage>)
}

export default DiscoveryView
