import React from 'react'
import styled from 'styled-components/macro'

// Calling it AppLabel... cuz why not? Open to suggestions
const AppLabel = styled.div`
  display: flex;
  text-align:center;
  padding: .3em 0 0 0;
  margin: 0;
`

const BrandText = styled.p`
  font-family: "Rockwell";
  font-size: 1.1em;
  text-shadow: 1px 1px 1px #000;
  color: #ffffff;
  margin: 0;
  padding: 0;
`

const Component = (props) => {
  return (
    <AppLabel>
      <BrandText>gitBabel |</BrandText>
    </AppLabel>
  )
}
export default Component
