import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

// Atoms
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import Chevron from '../../atoms/glyphs/closedChevron'
import ConsoleFrame from '../../atoms/consoleFrame'

const OverViewHeadline = styled.div`
    display: flex;
    flex-direction: row;
`

const OverviewSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25em 0 0.25em 0;
    font-family: Roboto;
    font-size: 20px;
    `
const OverviewItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2em 0 2em;
    font-family: Open Sans;
    // align-items: flex-start;
    `
const OverviewLabel = styled.span`
    `
const OverviewDynamic = styled.span`
    color: #A3A9B1;
    `

const SectionName = styled.span`
    width: 40%;
    font-size: 2em;
    padding: 0 0 .75em 0;
    `

const AccountSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
`

const CallToAction = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Upgrade = styled.span`
    color: #24A5FF
    padding: 1em 0 1em 0;
    font-family: Open Sans;
    font-weight: 900;
    `

const UsageSection = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-size: 18px;
`

const UsageRow = styled(OverViewHeadline)`
`
const UsageItem = styled.span`
    display: flex;
    flex-direction: column;
    padding: 1em 1em 1em 1em;
`

const ResetItem = styled.span`
    font-family: Open Sans;
    font-size: 10px;
    color: #A3A9B1;
`

const Overview = (props) => {
  return (
    <>
      <OverViewHeadline>
        <SectionName>Overview</SectionName>
        <AccountSection>
          <OverviewItem>
            <OverviewLabel>Type:</OverviewLabel>
            <OverviewDynamic>Organization</OverviewDynamic>
          </OverviewItem>
          <OverviewItem>
            <OverviewLabel>Plan:</OverviewLabel>
            <OverviewDynamic>Pro Workspace</OverviewDynamic>
          </OverviewItem>
        </AccountSection>
      </OverViewHeadline>
      {/* <UsageSection>
        Current Montly Usage
        <GreySkinnyRectangle />
        <UsageRow>
          <UsageItem style={{ paddingLeft: 0 }}>
            <OverviewLabel>Topics Processed*</OverviewLabel>
            <OverviewDynamic>500/10,000</OverviewDynamic>
          </UsageItem>
          <UsageItem>
            <OverviewLabel>API Requests*</OverviewLabel>
            <OverviewDynamic>5,132/10,000</OverviewDynamic>
          </UsageItem>
          <UsageItem>
            <OverviewLabel>Owners</OverviewLabel>
            <OverviewDynamic>32/100</OverviewDynamic>
          </UsageItem>
        </UsageRow>
        <ResetItem><i>* resets in X days on XX/XX/XXXX</i></ResetItem>
      </UsageSection> */}

      {/* <CallToAction> <div style={{ paddingRight: 5 }}><Chevron /></div> <Upgrade>Upgrade to Pro</Upgrade></CallToAction> */}
    </>
  )
}

export default Overview
