import React from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/client'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

// Queries
import { GET_REPOS, UPDATE_REPOSITORY_MUTATION } from '../../../lib/queries/console'

// Atoms
import GitRepository from '../../atoms/glyphs/repository'
import GreySkinnyRectangle from '../../atoms/skinnyGreyRectangle'
import QuestionMark from '../../atoms/glyphs/questionMark'
import ConsoleFrame from '../../atoms/consoleFrame'
import ConnectedRepo from '../../atoms/glyphs/doc-ops/repository/repoConnected'
import DisconnectedRepo from '../../atoms/glyphs/doc-ops/repository/repoDisconnected'

// Moleclues
import Loader from '../Loader'

// Organisms
import LibraryListing from '../../../doc-ops/organisms/libraryListing'

const RepoSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 0em 1em 0em;
    background-color: white;
    `

const RepoItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `

const RepoBreakdownLayout = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    padding: 1em 0 1em 0;
    `

const BlueHighlight = styled.span`
    color: #24A5FF;
    `
const DesktopTableView = styled.div`
    display: none;
    @media (min-width:641px)  {
      display: flex;
    }
    `

const MobileTableCardView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width:641px)  {
      display: none;
    }
    `
const MobileRepoCard = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    background-color: grey;
    border: solid 1px blue;
`

const RepoConfig = (props) => {
  const orgName = props.org

  // uuid: String!, $org: String!, $deleted: Boolean!)
  const [updateRepository, { mutationData, mutationLoading, mutationError }] = useMutation(UPDATE_REPOSITORY_MUTATION)

  const { loading, error, data } = useQuery(GET_REPOS, { variables: { org: orgName } })
  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }
  console.log('RepositoryData', data)

  const RepoTotal = data.repositories.length

  let active = 0
  let connected = 0
  for (let i = 0; i < RepoTotal; i++) {
    if (data.repositories[i].active === true) {
      active++
    }
    if (data.repositories[i].connected !== null) {
      connected++
    }
  }

  // console.log('active count', active)
  // console.log('connected count', connected)

  const RepoTable = data.repositories.map((repo, index) => {
    const RepoVisibility = repo.private ? ('Private') : ('Public')
    const RepoDescription = repo.description ? (repo.description) : ('No Description Provided')
    let RepoConnected
    switch (repo.connected) {
      case null:
        RepoConnected = <><DisconnectedRepo height={24} width={24} strokeColor='#a3a9b1' />Not Connected</>
        break
      case '.gitbabel/config.json':
        RepoConnected = <a target='_blank' href={`https://github.com/${repo.org}/${repo.name}/blob/master/.gitbabel/config.json`} rel='noopener noreferrer'><ConnectedRepo height={24} width={24} strokeColor='green' /></a>
    }

    return (
      <tr key={index}>
        <td>{repo.name}</td>
        {/* <td>{RepoDescription}</td> */}
        <td>{repo.org}</td>
        <td>{RepoVisibility}</td>
        <td>
          <Toggle
            id={index.toString()}
            defaultChecked={repo.active}
            onChange={() => {
              const payload = {
                uuid: repo.uuid,
                org: repo.org,
                active: !repo.active
              }

              const mutationObject = { variables: payload }
              updateRepository(mutationObject)
              // Mutation here using the payload
            }}
          />
          <label htmlFor='Active Toggle' />
        </td>
        <td>{RepoConnected}</td>
      </tr>
    )
  })

  const RepoCard = data.repositories.map((repo, index) => {
    const RepoVisibility = repo.private ? ('Private') : ('Public')
    const RepoEnabled = repo.active ? ('Disabled') : ('Active')
    let RepoConnected
    switch (repo.connected) {
      case null:
        RepoConnected = <DisconnectedRepo height={24} width={24} strokeColor='red' />
        break
      case '.gitbabel/config.json':
        RepoConnected = <ConnectedRepo height={24} width={24} strokeColor='green' />
    }
    return (
      <MobileRepoCard key={index}>
        <h1>{repo.name}</h1>
        <h3>{repo.org}</h3>
        {/* <span>{repo.description}</span> */}
        <p>Visibility: {RepoVisibility}</p>
        <p>Status: {RepoEnabled}</p>
        <Toggle
          id={index.toString()}
          defaultChecked={repo.active}
          onChange={() => {
            const payload = {
              uuid: repo.uuid,
              org: repo.org,
              active: !repo.active
            }

            const mutationObject = { variables: payload }
            updateRepository(mutationObject)
            // Mutation here using the payload
          }}
        />
        <p>{RepoConnected}</p>
      </MobileRepoCard>
    )
  })

  return (
    <ConsoleFrame>
      <RepoItem><div style={{ paddingRight: '.75em' }}><GitRepository /></div> Repository Configurations</RepoItem>
      <GreySkinnyRectangle />
      <RepoBreakdownLayout>
        <div>Indexed: {active}/{RepoTotal}</div>
        <div>Connected: {connected}/{RepoTotal}
          {/* <QuestionMark /> */}
        </div>
      </RepoBreakdownLayout>
      {/* <BlueHighlight> > See Details</BlueHighlight> */}
      <DesktopTableView>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Description</th> */}
              <th>Owner</th>
              <th>Visibility</th>
              <th>Enabled</th>
              <th>Connected</th>
            </tr>
          </thead>
          <tbody>
            {RepoTable}
          </tbody>
        </table>
      </DesktopTableView>
      <MobileTableCardView>
        {RepoCard}
      </MobileTableCardView>
    </ConsoleFrame>
  )
}

export default RepoConfig
