// React Include(s)
import React from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components'
import Select, { components } from 'react-select'

import { HEDGEWIG, NIGHTS_WATCH, LEGACY, ROBINS_EGG, WHITE, OPALZ } from '../../../core/colors'

const FilterControlboxStandard = styled.dl`
display: flex;
flex-direction:column
margin: 0 2.8em 0 0;
padding: 0;
position: relative;
top:-4px;
`

const FilterHeader = styled.dt`
font-family: 'Open Sans';
font-size: .9em;
color: #A2A2A2;
letter-spacing: -0.11px;
margin: 0;
padding: 0;
`

const FilterText = styled.dd`
font-family: 'Open Sans';
font-size: .9em;
color: ${LEGACY};
letter-spacing: -0.15px;
padding:0 0 0 0;
margin:0;
`

/*
 * Style Overrides - https://react-select.com/styles
 *
 * Overrides primarily were to remove padding on the controller-placeholder
 * and the separator+indicator/icon to open-collapse.
 * This includes `singleValue`, `valueContainer`, `placeholder`, container,
 * indicatorsContainer, indicatorSeparator,dropdownIndicator...
 * The `menu` / `option` defines the look of the dropdown options
 */
const customStyles = {
  /*
   * Custom Option Block Styles
   */
  option: (provided, state) => {
    let backgroundColor = WHITE
    // If select send in this background color
    if (state.isSelected) {
      backgroundColor = OPALZ
    // OnHover send in this background color
    } else if (state.isFocused) {
      backgroundColor = ROBINS_EGG
    }
    return {
      ...provided,
      borderBottom: `1px dotted ${HEDGEWIG}`,
      backgroundColor: backgroundColor,
      padding: 10,
      width: '300px'
    }
  },
  menu: (provided, state) => {
    const width = '200px'
    return { ...provided, width }
  },
  'option:hover': (provided, state) => {
    const backgroundColor = HEDGEWIG
    return { ...provided, backgroundColor }
  },

  /*
   * Everything below here is used to style the react-select controller frame and indicator
   */
  // This is the main housing for the control, we just override some of the defaults
  control: (provided, state) => {
    // Matching background for now
    const backgroundColor = HEDGEWIG
    const borderRadius = 6
    const border = 1
    // This took some work to get it this small
    // had to elminate padding all over
    const minHeight = '20px'

    return { ...provided, border, borderRadius, minHeight, backgroundColor }
  },
  /*
    * Unmarked class in `react-select`
    * This was hard to track down how to override
    * Defaults a padding
    * Defaults white background
    */
  valueContainer: (provided, state) => {
    const backgroundColor = HEDGEWIG
    const borderRadius = 6
    const border = 1
    const padding = '0 2px'
    return { ...provided, backgroundColor, border, borderRadius, padding }
  },
  placeholder: (provided, state) => {
    const padding = '0'
    return { ...provided, padding }
  },
  input: (provided, state) => {
    const padding = '0'
    return { ...provided, padding }
  },
  // backgroundColor is overriden by Control - but can bleed through from the border-radius in place
  container: (provided, state) => {
    // const backgroundColor = '#76C1F5'
    // const backgroundColor = '#ff0000'
    return { ...provided }
  },
  /* Styles for the dropdown indicator is spread across 3-namespaces
   * indicatorSeparator
   * indicatorsContainer
   * dropdownIndicator
   */
  // just hide the separator
  indicatorSeparator: () => ({}),
  // for the indicator keep the padding, but only on the right side
  indicatorsContainer: (provided, state) => {
    const padding = '0 8px 0 0'
    return { ...provided, padding }
  },
  // If we want to mount out own arrow icon
  // https://github.com/JedWatson/react-select/issues/3678
  dropdownIndicator: (provided, state) => {
    const padding = '0'
    return { ...provided, padding }
  }

}

/* Sample code for a grouping label -- will be used to organize repos into owners
 * Example: https://codesandbox.io/s/34p20?module=/example.js
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}></span>
  </div>
);
*/

const Option = props => {
  /*
   * While exploring how to do background hover, setup the custom option hook
   * TODO: Use this to embed `doc-ops/glpyhs` in here for the category menu
   * Maybe other Glyphs to hook, so consider using the <Glyph /> factory generic
   */
  // console.log('Option Wrapper',props);
  return (
    <div>
      <components.Option {...props} />
    </div>
  )
}

function TopicSelect (props) {
  function handleChange (selected) {
    props.handleChange(selected)
  }
  // const groupedOptions = [{label:props.header, options:props.options}]

  return (
    <FilterControlboxStandard>
      <FilterHeader>{props.header}</FilterHeader>
      <FilterText>
        {/*
          * menuIsOpen={true} -- Handy debugger to see menus open

        */}
        <Select
          styles={customStyles}
          components={{ Option }}
          value={props.value}
          options={props.options}
          onChange={handleChange}
        />
      </FilterText>
    </FilterControlboxStandard>
  )
}

TopicSelect.propTypes = {
  header: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default TopicSelect
