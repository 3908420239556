/*
 *  SUMMARY
 *
 *  <LibraryListing />  will act as the initial new Table based Libary Listing panel, should include the Filter controls and map
 *  some level of state to them.
 *
 *  NOTES:  Post creation of this, thinking we could move all the code into `views/libraryView` and just connect to our
 * `LibraryFilterControlBox` and `TopicListingTable` and remove this `libraryListing` comes down to how we plan on wiring up
 * the `state` and selection panels.
 */
// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Got some styling to do initially
import styled from 'styled-components/macro'

// Core UI Imports
import StyledLink from '../../core/atoms/styledLink'
import RepositoryBadge from '../../core/molecules/RepositoryBadge'

// Local Doc-Ops imports

import LibraryFilterControlBox from './LibraryFilterControlBox'
import LibraryListingTableGroup from './libraryListingTableGroup'
import FilterIcon from '../../core/atoms/glyphs/filter'

// These to organisms right into the `view/` and get rid of this component even.....
// REVIEW - ReactHooks useContext as we need to determine how we will mount state on the top-level view...
function LibraryListing (props) {
  const topics = props.topics

  let topicCounts = 0
  let totalRepos = 0

  // If we have topics, lets do some marshalling
  if (topics && topics.length > 0) {
    // Should iterate over Topics, need to get count of repos, we can do additional marshalling here as needed
    // iterate over and get a count of the repos?
    const repoMap = []
      
    topics.map((topic) => {
      // console.log(topic.repository);
      if (repoMap.includes(topic.repository) === false) {
        repoMap.push(topic.repository)
      }
    })

    // Post Marshalling, set out repoMap length as total repos 
    totalRepos = repoMap.length
    topicCounts = topics.length
  }

  return (
    <div>

      <LibraryFilterControlBox
        selectedCategory={props.selectedCategory}
        categoryList={props.categoryList}
        setSelectedCategory={props.setSelectedCategory}
        selectedOwner={props.selectedOwner}
        ownerList={props.ownerList}
        setSelectedOwner={props.setSelectedOwner}
        selectedRepo={props.selectedRepo}
        repoList={props.repoList}
        setSelectedRepo={props.setSelectedRepo}
        setSearchTerm={props.setSearchTerm}
      />

      {/*
        * Each LibraryListingTableGroup has a title, summary and set of topics to render
        * Will need an iterator here to do multiple groups-by topics
      */}
      <LibraryListingTableGroup sectionGroupTitle="All Topics" topics={topics} />
    </div>
  )
}

LibraryListing.propTypes = {
  selectedCategory: PropTypes.object.isRequired,
  categoryList: PropTypes.array.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedOwner: PropTypes.object.isRequired,
  ownerList: PropTypes.array.isRequired,
  setSelectedOwner: PropTypes.func.isRequired,
  selectedRepo: PropTypes.object.isRequired,
  repoList: PropTypes.array.isRequired,
  setSelectedRepo: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
}

export default LibraryListing
