/*
 *  SUMMARY
 *  
 *  <LibraryListing />  will act as the initial new Table based Libary Listing panel, should include the Filter controls and map
 *  some level of state to them.  
 * 
 *  NOTES:  Post creation of this, thinking we could move all the code into `views/libraryView` and just connect to our 
 * `LibraryFilterControlBox` and `TopicListingTable` and remove this `libraryListing` comes down to how we plan on wiring up
 * the `state` and selection panels.  
 */
// React Include
import React, { Component } from 'react';
// Got some styling to do initially
import styled from 'styled-components/macro';

// Core UI Imports
//import StyledLink from '../../core/atoms/styledLink'
//import RepositoryBadge from '../../core/molecules/repositoryBadge'

// Local Doc-Ops imports
import TopicListingTable from '../molecules/TopicListingTable/'


const GroupSectionFrame = styled.div`
    // donot apply width will break centering
    background-color: #fff;
    padding:1em;
`;

const SummaryLabel = styled.span`
    font-size:.6em;
    color: #ccc;
    font-weight:normal;
    padding-left:1em;
`;


function LibraryListingTableGroup(props){
  
  const topics = props.topics;

  let topicCounts = 0;
  let totalRepos = 0;

  // From our [topics] and get our TopicCount, Repos, and Owner Counts
  if (topics.length > 0){
    // Should iterate over Topics, need to get count of repos, we can do additional marshalling here as needed
      // iterate over and get a count of the repos?
      let repoMap = [];
      let ownerMap = [];
      
      topics.map((topic) => {
            //console.log(topic.repository);
            if (repoMap.includes(topic.repository) === false){
                repoMap.push(topic.repository)
            }
      });

      // Post Marshalling, set out repoMap length as total repos 
      totalRepos = repoMap.length
      topicCounts = topics.length
      
  }

  return (
        <GroupSectionFrame>
            <h2>{props.sectionGroupTitle} <SummaryLabel>{topicCounts} Topics in {totalRepos} Repositories</SummaryLabel></h2>

            
            
            {/* Each TopicListingTable has a title, summary and set of topics to render */}
            <TopicListingTable topics={topics} />
        </GroupSectionFrame>
  );
}

 export default LibraryListingTableGroup