import React from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'

// Local / Core Atoms
import AppLabel from '../atoms/appLabel'
import GlobalSearch from '../molecules/GlobalSearch'
import HamburgerMenu from '../atoms/glyphs/nav/hamburgermenu'
import CloseHamburgerMenu from '../atoms/glyphs/nav/closehamburgermenu'

import { GET_ME_QUERY } from '../../lib/queries/auth'

const AppActionBar = styled.div`
  display: flex;
  width: 100%;
  background-color: #343639;
  padding: 1em 0 1em 1em;
  margin: 0;
  flex-direction: row;
  color: white;
  align-items: center
`

// Will move this to a Atom once the circle issue is resolved
const Avatar = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: green;
`
const Username = styled.p`
  display: flex;
  padding 0 0 0 5px;
`

const MobileDotDisplay = styled.div`
  display: flex;
  margin: 0 1em 0 0;
  @media (min-width:769px) {
    display: none;
  }
`

const SearchDisplay = styled.div`
  display: none;
  align-items: center;
  @media (min-width:769px) {
    display: flex;
    padding: 0 1em 0 0;
  }
`

const AppActionBarRightSide = styled.div`
  display: flex;
// DO NOT REMOVE _auto_ from the end 
  margin: 0 1em  0 auto;
  justify-content: flex-end;
  color: #fff;
`

const Component = (props) => {
  const { loading, error, data } = useQuery(GET_ME_QUERY)

  const toggleNav = () => { props.setMobileNav(!props.isOpen) }

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>There was an error with GQL</div>
  }

  const user = data.me

  return (
    <AppActionBar>
      <AppLabel />
      <Avatar>
        <img src={`https://avatars.githubusercontent.com/u/${user.githubUserId}?v=4`} />
      </Avatar>
      <Username>{user.login}</Username>
      {/* Created the AppActionBarRightSide as a template/placeholder to help positioning */}
      <AppActionBarRightSide>
        <SearchDisplay><GlobalSearch /></SearchDisplay>
        <MobileDotDisplay onClick={toggleNav}>
          {props.isOpen ? <CloseHamburgerMenu /> : <HamburgerMenu />}
        </MobileDotDisplay>
      </AppActionBarRightSide>
    </AppActionBar>
  )
}

export default Component
