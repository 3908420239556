// Gives us the ability to create graphQl-Queries to mount
import { gql } from '@apollo/client'

// use gql function to generate a graphql template literal
export const GET_ME_QUERY = gql`
  query Me {
    me {
      uuid, firstName, lastName, email, isActive, login, githubUserId, org
    }
  }
`
