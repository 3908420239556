import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

// Import all our navigation icons
// import LibraryCardsIcon from '../atoms/glyphs/nav/libraryCards'
// import ReportsIcon from '../../atoms/glyphs/nav/reports'
// import LearnerIcon from '../atoms/glyphs/nav/learner'
// import InboxIcon from '../atoms/glyphs/nav/inbox'
import OnboardingIcon from '../../atoms/glyphs/nav/onboarding'
import DiscoveryIcon from '../../atoms/glyphs/nav/discovery'
import LibraryIcon from '../../atoms/glyphs/nav/library'
import ConsoleIcon from '../../atoms/glyphs/nav/console'
import LogoutIcon from '../../atoms/glyphs/nav/logout'

/* Combined our NavItems into a single Molecule */

const NavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  clear:both;
  font-family: 'Open Sans';
  font-weight: 300;
  padding:0;
  margin: 4em 0 0 0;
  // TODO Put in slush
  background-color: #dde0e4;
  font-size: 1em;
  align-items: center;
  justify-content: center;
`

// need to figure out how to add a `selected` so we can apply a border-left
const NavItem = styled.li`
  list-style:none;
  // TODO Color-Constant: LEGACY
  color: #343639;
  width:86px;
  text-align:center;
  padding:.25em 0;
  margin: 1em 0;
  // TODO Color-Constant: Slush
  border-left:3px solid #dde0e4;

  ${props => props.selected && css`border-left:3px solid #76C1F5; color:#dde0e4;`};
  
  
`

// TODO: determine how we will apply the `active` class in some cases, but ultimate we need a selected on the li
// https://github.com/styled-components/styled-components/issues/184
const StyledLink = styled(Link)`
  // TODO Color-Constant: LEGACY
  color: #343639;
  text-decoration: none;
  // TODO Color-Constant LEGACY 
  // TODO Color-Constant: NightsWatch - D3
  color: ${props => props.selected ? '#0d0e11' : '#343639'};
  // This is an example of how to do a CSS insertion for styled-components, was looking for this example
  //${props => props.selected && css` font-weight: bold;`};

  &:hover {
    text-decoration: none;
  }
  &.active {
    color: red;
  }
`

const onboardNavItemComponent = (onboardingLinkSelected) => {
  return (
    <NavItem selected={onboardingLinkSelected}>
      <StyledLink to='/onboarding' selected={onboardingLinkSelected}>
        <OnboardingIcon selected={onboardingLinkSelected} />Onboard
      </StyledLink>
    </NavItem>
  )
}

const consoleNavItemComponent = (consoleLinkSelected) => {
  return (
    <NavItem selected={consoleLinkSelected}>
      <StyledLink to='/console' selected={consoleLinkSelected}>
        <ConsoleIcon selected={consoleLinkSelected} />
        <br />Console
      </StyledLink>
    </NavItem>
  )
}

function component (props) {
  // NAV-MENU is outside of the <router> so we don't have access to the params
  // manual inspect the window.location.pathname
  // Don't love this, BUT, refactoring the <routes> to make this work properly will require significant effort
  const routePath = window.location.pathname
  // want to search for a slash, but need to start at non-zero index
  const hasTrailingSlash = routePath.indexOf('/', 1)
  let routeSelected = ''
  // check if there are more slashes, if not just return the substring 1 index in
  if (hasTrailingSlash !== -1) {
    routeSelected = routePath.substring(1, hasTrailingSlash)
  } else {
    routeSelected = routePath.substring(1)
  }

  // marshall local value for our url for express server
  // which we rely on for our cookie-destroying session hook
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL

  // need to check for the next `/` because it will jack up nav selector
  // console.log(routeSelected, '-- ',hasTrailingSlash);
  let onboardingLinkSelected = false
  let discoveryLinkSelected = false
  let libraryLinkSelected = false
  let consoleLinkSelected = false
  let reportsLinkSelected = false
  if (routeSelected === 'onboarding') {
    onboardingLinkSelected = true
  } else if (routeSelected === 'discovery') {
    discoveryLinkSelected = true
  } else if (routeSelected === 'library' || routeSelected === 'topic') {
    libraryLinkSelected = true
  } else if (routeSelected === 'reports') {
    reportsLinkSelected = true
  } else if (routeSelected === 'console') {
    consoleLinkSelected = true
  }

  let onboardNavItem = ''
  // React App ENVs will return a string, so converting to a bool would be extra work
  if (process.env.REACT_APP_SHOW_ONBOARDING !== 'false') {
    // Debuggers
    // console.log('onboarding detected, loading nav item', process.env.REACT_APP_SHOW_ONBOARDING)
    onboardNavItem = onboardNavItemComponent(onboardingLinkSelected)
  } else {
    // console.log('onboarding not detected, no nav item will be loaded', process.env.REACT_APP_SHOW_ONBOARDING)
    onboardNavItem = null
  }

  let consoleNavItem = ''

  if (process.env.REACT_APP_SHOW_CONSOLE !== 'false') {
    // Debuggers
    // console.log('onboarding detected, loading nav item', process.env.REACT_APP_SHOW_CONSOLE)
    consoleNavItem = consoleNavItemComponent(consoleLinkSelected)
  } else {
    // console.log('console not detected, no nav item will be loaded', process.env.REACT_APP_SHOW_CONSOLE)
    consoleNavItem = null
  }

  return (
    <NavMenu>

      {/* {Console} */}
      {consoleNavItem}

      {/* Onboarding */}
      {onboardNavItem}

      {/* Discovery */}
      <NavItem selected={discoveryLinkSelected}>
        <StyledLink to='/discovery' selected={discoveryLinkSelected}>
          <DiscoveryIcon selected={discoveryLinkSelected} />Discovery
        </StyledLink>
      </NavItem>

      {/* Library */}
      <NavItem selected={libraryLinkSelected}>
        <StyledLink to='/library' selected={libraryLinkSelected}>
          <LibraryIcon selected={libraryLinkSelected} />Library
        </StyledLink>
      </NavItem>

      {/* <NavItem selected={reportsLinkSelected}>
        <StyledLink to='/reports' selected={reportsLinkSelected}>
          <ReportsIcon selected={reportsLinkSelected} />Reports
        </StyledLink>
      </NavItem> */}
      <NavItem>
        {/* TODO Color-Constant: LEGACY */}
        <LogoutIcon />
        <br />
        <a style={{ color: '#343639', textDecoration: 'none' }} href={`${REACT_APP_API_URL}/logout`}>
          Logout
        </a>
      </NavItem>
    </NavMenu>
  )
}

export default component
