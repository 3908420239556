
import React from 'react'
/*
 *  The initial BackArrow being added for TopicViewer
 */
const BackArrow = () => (
  <div style={{}}>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g id="dropdownToggle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="ui/atoms/glyphs/back" stroke="#A3A9B1" strokeWidth="1.5">
                <g id="navigation-left-circle-1_1">
                    <path d="M7.501,11.997 L16.501,11.997" id="Shape"></path>
                    <polyline id="Shape" points="11.251 15.747 7.501 11.997 11.251 8.247"></polyline>
                    <circle id="Oval" cx="12.001" cy="11.997" r="10.5"></circle>
                </g>
            </g>
        </g>
    </svg>
  </div>
)


export default BackArrow
