import React, { Component } from 'react'

// Import our UI Components
import AppContainer from './core/templates/appContainer'

// import our AuthMiddleware
import AuthMiddleware from './router/authMiddleware'
import AppRoutes from './router/routes'

// App.css -- not sure if we use it even....
// import './App.css';

// Get our ReactRouter, which everything is bound here in app
import { BrowserRouter as Router } from 'react-router-dom'

// Upgrade to Apollo/Client 3.x has all interfaces under one package now
import { ApolloClient, ApolloProvider, InMemoryCache, gql } from '@apollo/client'
import { cache } from './cache'

import { LastLocationProvider } from 'react-router-last-location'

/**
 *  # Color / Palette
 *
 *  ## Greys/Blacks
 *  L3 - #F8F9FA
 *  L2 - #EDEFF0
 *  L1 - #DDE0E4
 *  BASE - #A3A9B1
 *  D1 - #6A6E74
 *  D2 - #343639
 *  D3 - #0D0E11

*/
class App extends Component {
  render () {
    // console.log('process.ENV', process.env);

    // hoist some APP CONSTANTS to ApolloClient
    const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL
    // console.log('CACHE: ', cache)
    //
    // DOCS: https://www.apollographql.com/docs/react/essentials/get-started
    const client = new ApolloClient({
      uri: GRAPHQL_API_URL,
      // Allow 3rd Party (api-calls), requires CORS enablement
      credentials: 'include',
      clientState: {
        defaults: {
          isAuthenticated: false,
          me: {
            __typename: 'Me'
          }
        },
        resolvers: {}
      },
      connectToDevTools: true,
      // ApolloClient 3.x requires a cache object
      // our cache is generated from ./cache.js
      cache: cache

    })

    /*
     * <Router> Setup the React-Router to wrap the entire app
     * <ApolloProvider /> Setup our ajax/client for Graphql Calls and Bind our Client Configuration
     * <AppContainer /> is where styled-components start, renders our first div's with Flexbox
     * <AuthMiddleware /> a wrapper for React-Router and our Authenticatin Layer, ensures users are logged in before
     *          mounting the remaining React Routes/Views
     */
    return (
      <Router>
        <LastLocationProvider>
          {/* mount GraphQL Authorized Client (ApolloBoost) */}
          <ApolloProvider client={client}>

            {/* design: core/template */}
            <AppContainer>

              {/* Router:Functional
              <AuthMiddleware /> will handle redirecting unauthenticated users to login on page load
              and if they are authenticated will return a <Switch /> of approved Application Routes

              If you don't have internet and can't auth, one can hijack with the main routes
              {AppRoutes()}  <-- NEVER SHIP THAT IN PLACE
            */}
              {/* <AuthMiddleware /> */}
              <AuthMiddleware />

            </AppContainer>
          </ApolloProvider>
        </LastLocationProvider>
      </Router>
    )
  }
}

export default App
