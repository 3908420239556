// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// include prism here
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// switched over to `cjs` from esm, caused the CI runner to explode
// There was an error about - Jest encountered an unexpected token
// https://stackoverflow.com/questions/58966891/nextjs-unexpected-token-import
import { coy } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import TopicParagraph from '../atoms/topicParagraph'
import StyledLink from '../../core/atoms/styledLink'

import { analyzeSectionsForTags } from '../../lib/tags'

import { InView } from 'react-intersection-observer'

// Import our react-wrapper around the `sanitize-html` interface
import SanitizedHTML from 'react-sanitized-html'

const TopicH2 = styled.h2`
  padding: 0 0 .5em 0;
  font-size: 1.8em;
  margin: 0 0 0 0;
  font-family: 'Open Sans';
  font-weight: 800;
  color: #384858;
  letter-spacing: -0.16px;
`

const TopicH3 = styled.h3`
  padding: 0 0 .5em 0;
  font-size: 1.5em;
  margin: 0 0 0 0;
  font-family: 'Open Sans';
  font-weight: 800;
  color: #384858;
  letter-spacing: -0.16px;
`

const TopicH4 = styled.h4`
  padding: 0 0 .5em 0;
  font-size: 1.2em;
  margin: 0 0 0 0;
  font-family: 'Open Sans';
  font-weight: 700;
  color: #384858;
  letter-spacing: -0.16px;
`

const TopicH5 = styled.h5`
  padding: 0 0 .5em 0;
  font-size: 1em;
  margin: 0 0 0 0;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #384858;
  letter-spacing: -0.09px;
`

/*
 * Special StyledComponent used to wrap `html` blocks so we can manually target and style the content
 * otherwise default styles are applied.
 * - <table> is the only html block we are currently styling
 */
const HtmlWrapper = styled.div` 
  // There is another div wrapper injected from the React-SanitizeHTML interface
  > div table {
    width: 100%;
    padding:.4em 0;  
    border: 1px solid #efefef;
    border-collapse: collapse;
  }
  // Give us some basic padding and text-size for all tables
  > div table td {
    padding: .4em 0;
    font-size: 1em;
  }
  // Want to bold the first column TD just to give that column some contrast
  > div table td:first-child {
    padding-left: .4em;
    font-weight: bold;
  }
  // Give us some Zebra Stripes for Table
  > div table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`

/*
 * TopicViewerBlockSection is where rubber going to meet road for each mapping each individual markdown block into a component
 * `blockOfContent`, the goal here is to provide the frame for rendering
 * each block as needed independent from the interaction-facade.
 * This component will be rendered as a child for TopicViewer Continuous || Sectional
 * This pattern will continue to evolve as more customer/job-stories are added to support a dynamic interface.
 * In closing, promote as an organism when ready to further develop.
 *
 * A little confusing here, because there needs to be a factory-wrapper at somepoint for blocks, but at the same time at the
 * section level we need away to stitch together a series of blocks.
 *
 * Still want ot hand in a set of blocks and get a set of blocks rolled up
 */
const TopicViewerSection = (props) => {
  // debugger for props
  // console.log('TopicViewerSection props', props)

  // We need a recursive children-crawler, that can aggregate the inline blocks for paragraph, list
  const blocks = analyzeSectionsForTags(props.blocks)
  const blockSet = blocks.map(block => {
    // console.log('Rendering Block -- ', block.type)
    // so yaml block is present, we just skip it so we miss a warning
    if (block.type === 'yaml') {
      return
    }
    // prevent Key errors in console, so make our own mini unique key
    const blockKey = 'd' + block.depth + '-parent-' + Math.floor(Math.random() * 10000)

    // console.log('BLOCKS: ', block)
    // We now support rendering HTML blocks from the markdown processing, we will sanitize it on insert
    // into Edgar, BUT as an added layer of protection, lets do another round of sanitizing just in case
    // to protect the user.
    if (block.type === 'html') {
      // Currently only allowing <Table> and the sub-tags tr/td
      // There is an advanced interface here we can configure
      // Of note is the ability to support iframe and what can be in `src` so we could support video embeds
      // Interface docs -- https://www.npmjs.com/package/sanitize-html
      return (<HtmlWrapper><SanitizedHTML allowedTags={['table', 'tr', 'td']} html={block.value} /></HtmlWrapper>)
    }
    if (block.type === 'heading' && block.depth === '2') {
      return (<TopicH2 key={blockKey}>{block.children[0].value}</TopicH2>)
    }
    if (block.type === 'heading' && block.depth === '3') {
      return (<TopicH3 key={blockKey}>{block.children[0].value}</TopicH3>)
    }
    if (block.type === 'heading' && block.depth === '4') {
      return (<TopicH4 key={blockKey}>{block.children[0].value}</TopicH4>)
    }
    if (block.type === 'heading' && block.depth === '5') {
      return (<TopicH5 key={blockKey}>{block.children[0].value}</TopicH5>)
    }
    // IMPORTANT: paragrahs act as containers to hold children items
    // Each portion of a paragraph such as `<strong>`, `<img />` etc are contained inline within paragraphs

    // CRITICAL: Paragraph needs abstracted, ListItems can contain them as blocks, so need reuse
    if (block.type === 'paragraph') {
      return (<TopicParagraph key={blockKey} block={block} meta={props.meta} />)
    }

    // List Blocks are like paragraphs and contain multiple items
    if (block.type === 'list') {
      // each child is a listItem, and will most likely have a paragraph block at children[0]
      // which we hand over to our TopicParagraph block
      const childBlocks = block.children.map(innerBlock => {
        const innerBlockKey = 'd' + block.depth + '-inner-' + Math.floor(Math.random() * 10000)
        return (
          <li key={innerBlockKey}>
            <TopicParagraph block={innerBlock.children[0]} meta={props.meta} />
          </li>
        )
      })
      return (<ul key={blockKey}>{childBlocks}</ul>)
    }

    if (block.type === 'code') {
      // sometimes lang can be null
      let lang = ''
      if (block.lang) {
        lang = block.lang.toLowerCase()
      }
      return (<SyntaxHighlighter key={blockKey} language={lang} style={coy}>{block.value}</SyntaxHighlighter>)
    }

    // !todo: address the blockquote issue
    // and this will let us know if we are missing other things
    console.warn('WARN: ', block.type)
  })

  // Return a raw section of markdown converted
  // Need to return a sane default
  // Learned we are missing
  return (
    <InView threshold='0.40' onChange={(inView, entry) => props.onScrollEvent(inView, entry)}>
      {({ inView, ref, entry }) => (
        <span ref={ref}>
          {blockSet}
        </span>
      )}
    </InView>
  )
}

TopicViewerSection.propTypes = {
  blocks: PropTypes.array.isRequired
}

export default TopicViewerSection
