// React Include
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Factory component for our markdown blocks
import TopicViewerSection from '../molecules/topicViewerSection'

// import StyledLink from '../../core/atoms/styledLink'

const TopicViewerBlockFrame = styled.div`
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 4px 5px 2px 0 rgba(229,229,229,0.50);
  border-radius: 4px;
  padding:2em;
  margin: 0 1em 3em 0;
  width: auto;
`

/*
 * TopicViewerSection is where rubber going to meet road for each block
 * Will need a block above it, to help setup and understand what type of doc w are rendering
 */
class TopicViewerContinuous extends Component {
  render () {
    //console.log('TopicViewerContinuous props', this.props)

    // this.props.topic.sections.map(topicSection => (
    // return this.createSections(topicSection)

    // ));

    // Get the sections from the props
    const sections = this.props.topic.sections
    const props = this.props
    // some blocks need to know some meta-data about the topic we are viewing (images especially)
    // need to build a new path if they are local in github
    // TODO add lodash and use _.pick()
    const meta = { owner: this.props.topic.owner, ref: this.props.topic.ref, filepath: this.props.topic.filepath, filename: this.props.topic.filename, repository: this.props.topic.repository }

    // console.log('what yo meta ', meta)
    // Used to get a block-fixture for the storybook in `doc-ops/atoms/topicParagraph
    // console.log('get block for story: ',JSON.stringify(section.blocks));
    let sectionMapped = []
    sectionMapped = sections.map(section => {
      // console.log('sending some blocks ',section);
      // We need a recursive children-crawler, that can aggregate the inline blocks for paragraph, list

      // prevent Key errors in console, so make our own mini unique key
      const blockKey = 'd' + section.order + '-parent-' + Math.floor(Math.random() * 10000)
      return (

        <TopicViewerSection id={this.props.topic.uuid} onScrollEvent={this.props.onScrollEvent} key={blockKey} blocks={section.blocks} meta={meta} />

      )

      // return blockSet;
    }) // < end the iterator for `sections`

    return (
      <TopicViewerBlockFrame>
        {sectionMapped}
      </TopicViewerBlockFrame>
    )
  }
}

TopicViewerContinuous.propTypes = {
  topic: PropTypes.object.isRequired,
  onScrollEvent: PropTypes.func
}

export default TopicViewerContinuous
