// React Include
import React from 'react'
import PropTypes from 'prop-types'

// Got some styling to do initially
import styled from 'styled-components/macro'

// Atoms
import GitBabelOwnerIcon from '../../atoms/glyphs/brands/GitBabelOwner'
// TODO see if we can make the BadgeLabel generic for all badges
import RepositoryBadgeLabel from '../../atoms/RepositoryBadgeLabel/RepositoryBadgeLabel'

/*
 * FrameMount can expect an override on the border
 */
const BadgeFrameMount = styled.div`
  display: flex;
  border-right: ${props => props.includeBorder ? '1px solid #EDEFF0' : 'none'};
  align-items: center;
  `

const IconAligner = styled.div`
    display: flex;
    padding: 0 0.5em 0 0;
`

/*
 * View Story for followup information
 */
function OwnerBadge (props) {
  const org = props.children
  //! Debugger for orgs without a SVG yet
  // const org = null
  let ownerIcon = ''

  switch (org) {
    case 'gitbabel':
      ownerIcon = <GitBabelOwnerIcon />
      break
    case 'gitbabel-hq':
      ownerIcon = <GitBabelOwnerIcon />
      break
    default:
      ownerIcon = null
      break
  }

  return (
    <BadgeFrameMount includeBorder={props.includeBorder}>
      {(ownerIcon !== null) ? <IconAligner>{ownerIcon}</IconAligner> : null}
      <RepositoryBadgeLabel>{props.children}</RepositoryBadgeLabel>
    </BadgeFrameMount>
  )
}

// https://reactjs.org/docs/typechecking-with-proptypes.html
// For single-children, https://reactjs.org/docs/typechecking-with-proptypes.html#requiring-single-child
// https://stackoverflow.com/questions/44582209/proptypes-in-functional-stateless-component
OwnerBadge.propTypes = {
  children: PropTypes.string.isRequired,
  includeBorder: PropTypes.bool
}

export default OwnerBadge
