import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

// Atoms
import WorkspaceGlyph from '../../atoms/workspace'
import ConsoleFrame from '../../atoms/consoleFrame'

// Moleclues
import Loader from '../Loader'

// Queries
import { GET_ME_QUERY } from '../../../lib/queries/auth'

// Components
import OverviewSection from './Overview'
import GithubSection from './GithubInstall'
import RepoSection from './RepoConfig'
import TopicsSection from './TopicList'

const Workspace = () => {
  const Frame = styled.div`
    display: flex;
    flex-direction: column;
    // background-color: lightgreen;
    `

  const BlueBox = styled.div`
    background-color: #DCF0FF;
    padding: 1em 1em 1em 1em;
    margin: 1em 0 1em 0;
    `

  const WorkspaceHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `

  const WorkspaceTitle = styled.span`
    font-family: Open Sans;
    font-weight: 800;
    padding-right: 1em;
    font-size: 1.4em;
    `

  const { loading, error, data } = useQuery(GET_ME_QUERY)

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>GraphQL Blew Up</div>
  }

  const userData = data.me

  return (
    <Frame>
      <ConsoleFrame>
        <OverviewSection {...userData} />
        <GithubSection {...userData} />
        <BlueBox>
          <WorkspaceHeader style={{ paddingBottom: '1em' }}><div style={{ paddingRight: '1em' }}><WorkspaceGlyph width={36} height={36} /></div> <WorkspaceTitle>Workspace Summary</WorkspaceTitle></WorkspaceHeader>
          <RepoSection {...userData} />
          <TopicsSection />
        </BlueBox>
      </ConsoleFrame>

    </Frame>
  )
}

export default Workspace
